@import "./variables.scss";

$btn-post-bg: #fafaaa;
$btn-post-border-color: $input-border-color;
$btn-post-color: $gray-600;

.chat-message-form {

  textarea {
    border-radius: .5rem;
  }

  .btn-post {
    @include button-variant($btn-post-bg, $btn-post-border-color);
    border-radius: 10rem;
    color: $btn-post-color;
    font-size: ($font-size-base * .8);
    font-weight: 500;
    letter-spacing: .1rem;
    padding: .25rem 5rem;

    &.disabled,
    &:disabled {
      color: $btn-post-color;
    }
  }
}