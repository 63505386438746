@import "./variables.scss";

@import "bootstrap/scss/alert";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/card";
@import "bootstrap/scss/close";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/media";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/type";
@import "bootstrap/scss/utilities";

.transition-list {
  &-enter {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    transform: translate(-25%, 0);
  }

  &-enter-active {
    transform: translate(0, 0);
    @include transition($transition-page);
  }

  &-exit {
    transform: translate(0, 0);
  }

  &-exit-active {
    transform: translate(-25%, 0);
    @include transition($transition-page);
  }
}

.transition-page {
  &-enter {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    box-shadow: -.25rem 0 .5rem rgba(0, 0, 0, .1);
    transform: translate(100%, 0);
    z-index: 1;
  }

  &-enter-active {
    transform: translate(0, 0);
    @include transition($transition-page);
  }

  &-exit {
    transform: translate(0, 0);
  }

  &-exit-active {
    transform: translate(100%, 0);
    @include transition($transition-page);
  }
}

@keyframes placeholder-shimmer{
  0% { background-position: -500px 0; }
  100% { background-position: 500px 0; }
}

.placeholder-background {
  $gradient: linear-gradient(to right, $gray-100 0%, $gray-200 20%, $gray-100 40%, $gray-100 100%);
  // animation: placeholder-shimmer 1s linear infinite forwards;
  background: $gradient center / 800px 400px no-repeat, $gray-100;
}

.svg-inline {
  display: inline-block;
  vertical-align: -.125em;
  width: 1.25em;
  height: 1em;

  .fa-primary {
    opacity: 1;
  }

  .fa-secondary {
    opacity: .6;
  }
}
