@import "./variables.scss";

.system-message {
  padding: 0 1rem;

  @include media-breakpoint-up(sm) {
    padding: 0 1.5rem;
  }
}

.system-message--dark {
  color: $white;
}

.system-message-arrow {
  border-color: transparent transparent transparent currentColor;
  border-style: solid;
  border-width: .3rem 0 .3rem .6rem;
  content: "";
  display: inline-block;
  margin-right: .6rem;
  height: 0;
  width: 0;
}