@import "./variables.scss";

.room-card-placeholder {
  border-color: $gray-200;
  border-width: 1px;

  .card-img-top {
    @include media-breakpoint-only(xs) {
      height: 120px;
    }

    @include media-breakpoint-up(sm) {
      height: 150px;
    }
  }
}

.room-card-body-placeholder {
  $text-height: .6rem;
  $text-spacer: .5rem;
  $block-spacer: 1.1rem;

  height: $h5-font-size + $block-spacer * 2 + $text-height * 4 + $text-spacer * 2;
  position: relative;

  & > div {
    background: $white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  .title-right {
    left: 10rem;
    height: $h5-font-size;
  }

  .title-bottom {
    top: $h5-font-size;
    height: $block-spacer;;
  }

  .description-spacer-1 {
    top: $h5-font-size + $block-spacer + $text-height;
    height: $text-spacer;
  }

  .description-spacer-2 {
    top: $h5-font-size + $block-spacer + $text-height * 2 + $text-spacer;
    height: $text-spacer;
  }

  .description-right {
    left: 8rem;
    top: $h5-font-size + $block-spacer + $text-height * 2 + $text-spacer * 2;
    height: $text-height;
  }

  .description-bottom {
    top: $h5-font-size + $block-spacer + $text-height * 3 + $text-spacer * 2;
    height: $block-spacer;
  }

  .link-right {
    left: 4rem;
    top: $h5-font-size + $block-spacer * 2 + $text-height * 3 + $text-spacer * 2;
    height: $text-height;
  }
}