@import "./variables.scss";

.room-cards {
  .room-card, .room-card-placeholder, .add-room-link {
    margin-bottom: $grid-gutter-width;
  }

  .add-room-link {
    border-style: dashed;
    border-width: 1px;
    padding: $card-spacer-x;

    @include transition(
      mdc-elevation-transition-value(),
      /* Configure border-color to use same duration and easing values as elevation */
      border-color $mdc-elevation-transition-duration $mdc-elevation-transition-timing-function
    );
    will-change: $mdc-elevation-property, border-color;

    &:hover {
      @include mdc-elevation(2);
      border-color: transparent;
      text-decoration: none;
    }
  }

  .icon-plus {
    .fa-primary { color: #FFA726; }
    .fa-secondary {
      color: #D32F2F;
      opacity: 1;
    }
  }
}
