@import "./variables.scss";

.room-card-image, .room-card-image-loading, .room-card-image-broken {
  @include media-breakpoint-only(xs) {
    height: 120px;
    line-height: 120px;
  }

  @include media-breakpoint-up(sm) {
    height: 150px;
    line-height: 150px;
  }
}

.room-card-image-broken {
  background: $gray-100;
  color: $gray-600;
  font-size: $small-font-size;
}

.room-card-image {
  object-fit: cover;
}