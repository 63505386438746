@import "./variables.scss";

.room-card {
  @include mdc-elevation(2);

  .card-text {
    font-size: .9rem;
    margin-bottom: $card-spacer-y;
  }

  .user-avatar {
    display: inline-block;
    margin-right: .25rem;
    vertical-align: middle;
    height: 22px;
    width: 22px;
  }

  .user-avatar-border {
    border-width: .1rem;
  }
}
