@import "./variables.scss";

.room-container {
  min-height: 100vh;
  overflow: hidden;

  .scroll-to-element-button {
    position: fixed;
    bottom: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);
  }
}