@import "./variables.scss";

.user-avatar {
  background: center / 100% no-repeat, darken($gray-100, 3%);
  border-radius: 24%;
  width: 2.8rem;
  height: 2.8rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.user-avatar-border {
  border-style: solid;
  border-width: .2rem;
}

@include media-breakpoint-up(sm) {
  .user-avatar {
    width: 3.2rem;
    height: 3.2rem;
  }
}