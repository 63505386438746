@import "./variables.scss";

.room-header {

  .container {
    flex-wrap: nowrap;
  }

  .navbar-nav {
    flex-direction: row;

    .nav-link {
      padding: $nav-link-padding-y $navbar-nav-link-padding-x;
    }
  }

  .icon-users {
    .fa-primary { color: #FFA726; }
    .fa-secondary { color: #0879C4; }
  }
}
