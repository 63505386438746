@import "./variables.scss";

$enter-duration: 250ms;
$exit-duration: 200ms;
$transition-modal-backdrop-enter: mdc-animation-enter(opacity, $enter-duration);
$transition-modal-backdrop-exit: mdc-animation-exit-permanent(opacity, $exit-duration);
$transition-modal-content-enter: mdc-animation-enter(opacity, $enter-duration), mdc-animation-enter(transform, $enter-duration);
$transition-modal-content-exit: mdc-animation-exit-permanent(opacity, $exit-duration), mdc-animation-exit-permanent(transform, $exit-duration);

.modal {
  background: rgba($black, .45);
  display: block;
  opacity: 0;
}

.modal-after-open {
  @include transition($transition-modal-backdrop-enter);
  opacity: 1;
}

.modal-before-close {
  @include transition($transition-modal-backdrop-exit);
  opacity: 0;
}

.modal-dialog .modal-content {
  opacity: 0;
  transform: translateY(150px) scale(.8);
}

.modal-dialog-after-open .modal-content {
  @include transition($transition-modal-content-enter);
  opacity: 1;
  transform: translateY(0) scale(1);
}

.modal-dialog-before-close .modal-content {
  @include transition($transition-modal-content-exit);
  opacity: 0;
  transform: translateY(150px) scale(.8);
}

.modal-body {
  color: $text-muted;
}

.modal-header {
  padding-bottom: 0;
}

.modal-footer {
  padding: $modal-inner-padding - $btn-padding-y $modal-inner-padding - $btn-padding-x;
}