@import "./variables.scss";

/** the arrow is an isosceles triangle pointing left or right */
$chat-message-arrow-half-base: .25rem;
$chat-message-arrow-height: 1rem;
$chat-message-arrow-inner-offset: .6rem;

$chat-message-border-width: .2rem;
$chat-message-border-radius: .6rem;
$chat-message-padding-y: .6rem;
$chat-message-padding-x: $chat-message-padding-y * 2;
$chat-message-padding-y-sm: .45rem;
$chat-message-padding-x-sm: $chat-message-padding-y-sm * 2;

.chat-message {

  .user-avatar {
    flex: 0 0 auto;
  }

  .media-body {
    flex: 0 1 auto;
    mask-image: linear-gradient(rgba(0, 0, 0, .975) 0, $black 50%, rgba(0, 0, 0, .925) 50%, $black 100%);
  }
}

.chat-message-content {
  border-radius: $chat-message-border-radius;
  border: $chat-message-border-width solid $white;
  padding: $chat-message-padding-y-sm $chat-message-padding-x-sm;
  word-break: break-word;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
    padding: $chat-message-padding-y $chat-message-padding-x;
  }
}

.chat-message-arrow, .chat-message-arrow-outer, .chat-message-arrow-inner {
  position: absolute;
}

.chat-message-arrow {
  height: $chat-message-arrow-half-base * 2;
  width: $chat-message-arrow-height;
  top: $chat-message-padding-y-sm + ($font-size-sm * $line-height-base / 2) - $chat-message-arrow-half-base;

  @include media-breakpoint-up(sm) {
    top: $chat-message-padding-y + ($font-size-base * $line-height-base / 2) - $chat-message-arrow-half-base;
  }
}

.chat-message-arrow-outer, .chat-message-arrow-inner {
  border: solid transparent;
  border-width: $chat-message-arrow-half-base $chat-message-arrow-height;
}

.chat-message--left {
  .chat-message-content {
    margin-left: $chat-message-arrow-height + .5rem;
  }

  .chat-message-arrow {
    left: -$chat-message-arrow-height;
  }

  .chat-message-arrow-outer, .chat-message-arrow-inner {
    border-left-width: 0;
  }

  .chat-message-arrow-outer {
    left: 0;
  }

  .chat-message-arrow-inner {
    left: $chat-message-arrow-inner-offset;
  }
}

.chat-message--right {
  flex-direction: row-reverse;

  .chat-message-content {
    margin-right: $chat-message-arrow-height + .5rem;
  }

  .chat-message-arrow {
    right: -$chat-message-arrow-height;
  }

  .chat-message-arrow-outer, .chat-message-arrow-inner {
    border-right-width: 0;
  }

  .chat-message-arrow-outer {
    right: 0;
  }

  .chat-message-arrow-inner {
    right: $chat-message-arrow-inner-offset;
  }
}

.chat-message--light {
  .chat-message-content {
    background: $white;
    border-color: $dark; // avatar color
  }

  .chat-message-arrow-outer {
    border-color: transparent $dark; // avatar color
  }

  .chat-message-arrow-inner {
    border-color: transparent $white;
  }
}

.chat-message--dark {
  .chat-message-content {
    background: $dark; // avatar color
    color: $white;
  }

  .chat-message-arrow-outer {
    border-color: transparent $white;
  }

  .chat-message-arrow-inner {
    border-color: transparent $dark; // avatar color
  }
}

.chat-message--sending {
  opacity: .65;
}