@import "./variables.scss";

.navbar {
  @include media-breakpoint-down(lg) {
    .container-xl {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .user-avatar {
    width: 2rem;
    height: 2rem;
  }

  .user-avatar-border {
    border-width: .13rem;
  }
}