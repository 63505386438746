@import "@material/animation/functions";
@import "@material/elevation/mixins";

$transition-page: mdc-animation-standard(transform, 350ms);

// Bootstrap variable overrides.
$alert-padding-y: .5rem;
$card-border-width: 0;
$card-columns-gap: 1rem;
$card-columns-margin: 1rem;
$card-spacer-x: 1rem;
$card-spacer-y: .5rem;
$component-active-bg: #6c757d;
$enable-responsive-font-sizes: true;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;
$navbar-padding-y: .25rem;
$grid-gutter-width: 16px;
$container-padding-x: $grid-gutter-width;
$transition-collapse: mdc-animation-standard(height, 250ms);

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$card-inner-border-radius: $card-border-radius;

// Overwrite make-container to have configurable container padding.
// Copied from bootstrap master branch, which will be included in v5.
// TODO: remove once upgraded to bootstrap v5.
@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}
