@import "./variables.scss";

.home-page {

  .room-card-display-group {
    position: relative;
    height: 310px;
    width: 300px;
    perspective: 600px;

    .room-card-display {
      position: absolute;
      height: 100%;
      width: 100%;
      background: $dark;
      transform: rotateY(10deg);
      transform-origin: 0 60%;

      &:nth-child(1) {
        left: -70px;
        transform: rotateY(10deg) scale(.6);

        .room-card-display-inner {
          opacity: .4;
        }
      }

      &:nth-child(2) {
        left: -40px;
        transform: rotateY(10deg) scale(.8);

        .room-card-display-inner {
          opacity: .8;
        }
      }
    }
  }

  .avatar-list-display {
    .user-avatar {
      display: inline-block;
      margin: .5rem;

      &:nth-child(1) {
        transform: translate(2rem, 1rem) scale(.4);
      }

      &:nth-child(2) {
        transform: translate(1rem, 1rem) scale(.8);
      }

      &:nth-child(3) {
        transform: translate(1rem, 1rem);
      }

      &:nth-child(4) {
        transform: translate(-2rem, 2rem) scale(1.8);
      }

      &:nth-child(5) {
        transform: translate(0, -1rem);
      }

      &:nth-child(6) {
        transform: scale(.8);
      }

      &:nth-child(7) {
        transform: translate(0, 1rem);
      }

      &:nth-child(8) {
        transform: translate(-2rem, -1rem);
      }

      &:nth-child(9) {
        transform: translate(-2rem, -1rem) scale(.6);
      }

      &:nth-child(10) {
        transform: translate(-2rem, 0) scale(.6);
      }

      &:nth-child(11) {
        transform: translate(1rem, 1rem);
      }

      &:nth-child(12) {
        transform: scale(2.2);
      }

      &:nth-child(13) {
        transform: translate(1rem, 1rem);
      }

      &:nth-child(14) {
        transform: scale(1.2);
      }

      &:nth-child(15) {
        transform: translate(1rem, 2rem) scale(.8);
      }
    }
  }

  .share-links a {
    @include transition($btn-transition);
    color: $gray-300;
    display: inline-block;
    font-size: 1.2rem;
    padding: .6rem;

    &:first-child {
      margin-left: -.6rem;
    }

    &:hover, &:focus {
      color: $gray-100;
    }
  }

  footer {
    p {
      color: $gray-500;
    }
  }

  @include media-breakpoint-down(md) {
    .room-card-display-group {
      transform: scale(.7);
    }
  }
}
